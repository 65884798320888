import ProductType from '../ProductType';

export interface SkateboardingTypesInterface {
  deck: ProductType;
  griptape: ProductType;
  trucks: ProductType;
  wheels: ProductType;
}

const skateboardingTypes: SkateboardingTypesInterface = {
  deck: new ProductType('skateboarding_deck'),
  griptape: new ProductType('skateboarding_griptape'),
  trucks: new ProductType('skateboarding_trucks'),
  wheels: new ProductType('skateboarding_wheels')
};

export default skateboardingTypes;
