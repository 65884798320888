import ProductType from '../ProductType';

export interface ClothesTypesInterface {
  pants: ProductType;
  shirt: ProductType;
  sweatShirt: ProductType;
  tshirt: ProductType;
}

const clothesTypes: ClothesTypesInterface = {
  pants: new ProductType('pants'),
  shirt: new ProductType('shirt'),
  sweatShirt: new ProductType('sweatshirt'),
  tshirt: new ProductType('tshirt')
};

export default clothesTypes;
