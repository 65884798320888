import ProductType from '../ProductType';

export interface AccessoriesTypesInterface {
  beanie: ProductType;
  backpack: ProductType;
  bag: ProductType;
  belt: ProductType;
  beltBag: ProductType;
  cap: ProductType;
  keychain: ProductType;
  socks: ProductType;
  sunglasses: ProductType;
  wallet: ProductType;
}

const accessoriesTypes: AccessoriesTypesInterface = {
  beanie: new ProductType('beanie'),
  backpack: new ProductType('backpack'),
  bag: new ProductType('bag'),
  belt: new ProductType('belt'),
  beltBag: new ProductType('beltBag'),
  cap: new ProductType('cap'),
  keychain: new ProductType('keychain'),
  socks: new ProductType('socks'),
  sunglasses: new ProductType('sunglasses'),
  wallet: new ProductType('wallet')
};

export default accessoriesTypes;
